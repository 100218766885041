import { CommonModule, DatePipe } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Network } from '@ionic-native/network/ngx';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { IonicModule } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { SharedModule } from '../shared/shared.module';
import { enterAnimation } from './animations/animationNavigation';
import { httpInterceptorProviders } from './interceptors';
import { WoocommerceCategoriesService,
         WoocommerceCustomerService,
         WoocommerceProductsService,
         WoocommerceTagsService, WoocommerceHelperService, WoocommerceOrderService } from './services/wooapi/wooApi';

const maskConfig: Partial<IConfig> = {
    validation: false,
};

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        BrowserModule,
        SharedModule,
        HttpClientModule,
        IonicModule.forRoot({
          hardwareBackButton: true,
          rippleEffect: false,
          navAnimation: enterAnimation
        }),
        NgxMaskModule.forRoot(maskConfig),
        IonicStorageModule.forRoot({
          name: '__DeliveryTemplate',
          driverOrder: ['indexeddb', 'sqlite', 'websql']
        })
    ],
    exports: [],
    providers: [
      httpInterceptorProviders,
      DatePipe,
      HttpClient,
      HttpClientModule,
      ScreenOrientation,
      WoocommerceCustomerService,
      WoocommerceProductsService,
      WoocommerceCategoriesService,
      WoocommerceTagsService,
      WoocommerceHelperService,
      WoocommerceOrderService,
      Network,
      OneSignal,
      InAppBrowser
    ]
})
export class CoreModule { }
