import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-top-popover',
  templateUrl: './top-popover.component.html',
  styleUrls: ['./top-popover.component.scss'],
})
export class TopPopoverComponent implements OnInit {
  @Input() text: string;
  @Input() closeEnable: boolean;

  constructor(
    private popoverController: PopoverController
  ) { }

  ngOnInit() {}

  async cancelPopover() {
    await this.popoverController.dismiss();
  }
}
