import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { ThemeOptionsService } from './theme-options.service';

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {
  currentLanguage: string;
  constructor(
    private storage: Storage,
    private themeOptionsService: ThemeOptionsService
  ) { }

  async findAndApplyCurrentAppLanguage() {
    await this.storage.get('language').then(lang => {
      if (lang) {
        this.currentLanguage = lang;
        this.setDefaultLanguage(this.currentLanguage, true);
        return this.currentLanguage;
      } else {
        this.currentLanguage = 'ua';
        this.setDefaultLanguage(this.currentLanguage);
      }
    });
    return this.currentLanguage;
  }

  async setDefaultLanguage(language, disabledReload?) {
    await this.storage.set('language', language);
    if (!disabledReload) {
      this.reloadApp();
    }
  }

  reloadApp() {
    // @ts-ignore
    window.location = window.location.origin;
  }
}
