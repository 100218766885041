import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.scss'],
})
export class CounterComponent implements OnInit {
  @Input() count: number;
  @Input() additionalClasses: string;
  @Input() minValue = 0;
  @Input() maxValue: number;
  @Input() disabledButton: boolean;
  @Output() countChange: EventEmitter<number> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onCount(increment: boolean) {
    if (increment) {
      if (this.count >= this.maxValue) {
        this.count = this.maxValue;
      } else {
        this.count++;
        this.countChange.emit(this.count);
      }
    } else {
      if (this.count > this.minValue) {
        this.count--;
        this.countChange.emit(this.count);
      }
    }
  }
  changeQuantity() {

  }

}
