import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-informer-block',
  templateUrl: './informer-block.component.html',
  styleUrls: ['./informer-block.component.scss'],
})
export class InformerBlockComponent implements OnInit {
  @Input() informerData: any;

  constructor() { }

  ngOnInit() {}

}
