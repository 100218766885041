import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { AlertService } from 'src/app/core/services/alert.service';

@Injectable()
export class ContactForm7Service {
  constructor(private http: HttpClient, private alertService: AlertService) { }

  sendForm(formId, body): Observable<any> {
    return this.http.post(`wp-json/contact-form-7/v1/contact-forms/${formId}/feedback`, body).pipe(
      catchError((error) => {
        this.alertService.presentErrorAlert(error);
        return throwError(error);
      })
    );
  }

}
