import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-preview-thumb-link-skeleton',
  templateUrl: './preview-thumb-link-skeleton.component.html',
  styleUrls: ['./preview-thumb-link-skeleton.component.scss'],
})
export class PreviewThumbLinkSkeletonComponent implements OnInit {
  @Input() additionalClasses: string;

  constructor() { }

  ngOnInit() {}

}
