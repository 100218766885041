import {ReplaySubject, throwError} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {catchError, take} from 'rxjs/operators';
import {AlertService} from './alert.service';

@Injectable({
  providedIn: 'root'
})

export class ThemeOptionsService {
  optionsLoaded = false;
  generalInfoLoaded = false;
  public getOptions$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public getGeneralInfo$: ReplaySubject<any> = new ReplaySubject<any>(1);
  token: string;

  constructor(private http: HttpClient,
              private alertService: AlertService) {
  }

  loadOptions() {
    return this.http.get(`${environment.acfEndpoint}/options/options`).pipe(
      take(1)
    ).subscribe(
      data => {
        this.optionsLoaded = true;
        this.getOptions$.next(data);
      },
      error => {
        this.alertService.presentErrorAlert(error);
      }
    );
  }

  get getData() {
    if (!this.optionsLoaded) {
      this.loadOptions();
    }
    return this.getOptions$;
  }

  loadGeneralInfo(token) {
    return this.http.post(`${environment.rsEndpoint}/generalInfo`, token).pipe(
      take(1)
    ).subscribe(
      data => {
        this.generalInfoLoaded = true;
        this.getGeneralInfo$.next(data);
      },
      error => {
        this.alertService.presentErrorAlert(error);
      }
    );
  }
  get getGeneralData() {
    if (!this.generalInfoLoaded) {
      this.loadGeneralInfo(this.token);
    }
    return this.getGeneralInfo$;
  }

  getiikostreets(city){
    return this.http.post(`${environment.rsEndpoint}/getStreets`, city).pipe(
      catchError((error) => {
        this.alertService.presentErrorAlert(error);
        return throwError(error);
      })
    );
  }

  getZone(city){
    return this.http.post(`${environment.rsEndpoint}/getZones?city=${city}`, city).pipe(
      catchError((error) => {
        this.alertService.presentErrorAlert(error);
        return throwError(error);
      })
    );
  }
}
