import {Injectable} from '@angular/core';
import {AlertController} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})

export class AlertService {
  constructor(
    private alertController: AlertController
  ) {
  }

  async presentErrorAlert(err) {
    // console.log(err.loaded, err.total);
    if (err.loaded === 0 && err.total === 0) {
      return;
    }
    let mess;
    const errCode = err.error && err.error.code ? err.error.code : err.code;
    const buttonsArr: any = ['OK'];

    if (typeof (err?.error?.message) === 'string') {
      mess = err.error.message;
    } else if (typeof (err.message) === 'string') {
      mess = err.message;
    } else if (typeof (err) === 'string') {
      mess = err;
    }

    const alert = await this.alertController.create({
      header: 'Помилка',
      message: mess ? mess : 'Виникла невідома помилка',
      buttons: buttonsArr
    });

    await alert.present();
  }

  async presentNetworkError(cb?: any) {
    const alert = await this.alertController.create({
      header: 'Помилка',
      message: 'Немає зв’язку з сервером, перевірте, будь ласка, з’єднання з інтернетом',
      backdropDismiss: false
    });
    await alert.present();
    if (cb) {
      cb(alert);
    }
  }

  async createAlert(options: any) {
    const {header, message, backdropDismiss, buttons, cssClass} = options;
    const alert = await this.alertController.create({
      header,
      message,
      backdropDismiss,
      buttons,
      cssClass
    });
    await alert.present();
  }
}
