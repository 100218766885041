import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { NavController } from '@ionic/angular';
import { ProductExtendedInterface } from 'src/app/core/interfaces/product-extended.interface';

@Component({
  selector: 'app-custome-toastr',
  templateUrl: './custome-toastr.component.html',
  styleUrls: ['./custome-toastr.component.scss'],
})
export class CustomeToastrComponent implements OnInit, OnChanges {
  @Input() product: ProductExtendedInterface;
  @Input() showToastr: boolean;
  @Output() prematureClouse = new EventEmitter<boolean>();
  timer: any;
  constructor(
    public navCtrl: NavController
  ) { }

  ngOnInit() {}

  ngOnChanges() {
    if (this.showToastr) {
      this.timer = setTimeout(() => {
        this.showToastr = false;
        this.prematureClouse.emit(this.showToastr);
      }, 2000);
    } else {
      clearTimeout(this.timer);
      this.showToastr = false;
      this.prematureClouse.emit(this.showToastr);
    }
  }

  hideToastr() {
    this.showToastr = false;
    this.prematureClouse.emit(this.showToastr);
  }
}
