import {Component, Input, OnInit} from '@angular/core';
import {NavController} from '@ionic/angular';

@Component({
  selector: 'app-preview-thumb-link',
  templateUrl: './preview-thumb-link.component.html',
  styleUrls: ['./preview-thumb-link.component.scss'],
})
export class PreviewThumbLinkComponent implements OnInit {
  @Input() thumbTitle: string;
  @Input() thumbImg: string;
  @Input() thumbRoute: number | string;
  @Input() thumbRouteExtras: any = {};
  @Input() additionalClasses: string;
  constructor(private navController: NavController) { }

  ngOnInit() {}

  navigateForward() {
    this.navController.navigateForward([this.thumbRoute], this.thumbRouteExtras);
  }
}
