import { AnimationController, Animation } from "@ionic/angular";

export const enterAnimation = (baseEl: HTMLElement, opts?: any): Animation => {
  const animationController = new AnimationController();

  if (opts.direction === 'forward') {
    return animationController.create().addElement(opts.enteringEl).duration(300).easing('ease-in').fromTo('opacity', 0, 1);
  } else {
    const enteringAnimation = animationController.create().addElement(opts.enteringEl).duration(300).easing('ease-in').fromTo('opacity', 0, 1);
    const leavingAnimation = animationController.create().addElement(opts.leavingEl).duration(300).easing('ease-out').fromTo('opacity', 1, 0);
    return animationController.create().addAnimation([enteringAnimation, leavingAnimation]);
  }
};
