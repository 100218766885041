import {AfterContentChecked, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { finalize } from 'rxjs/operators';
import { AlertService } from 'src/app/core/services/alert.service';
import { CartService } from 'src/app/core/services/cart.service';
import SwiperCore,{ EffectCoverflow, Navigation, Pagination, Scrollbar, SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import {ProductExtendedInterface} from '../../../core/interfaces/product-extended.interface';
import { IngredientsDetailComponent } from '../ingredients-detail/ingredients-detail.component';
SwiperCore.use([Navigation, Pagination, Scrollbar, EffectCoverflow]);

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
})
export class ProductCardComponent implements OnInit, AfterContentChecked {
  @ViewChild('swiper') swiper: SwiperComponent;
  @Output() ingredientArray: EventEmitter<any> = new EventEmitter();
  @Input() product: ProductExtendedInterface;
  @Input() inCatalog: boolean;
  @Input() inDetail: boolean;
  @Input() inFavorite: boolean;
  isFavourite = false;
  ingredientsList = [];
  ingredients = [];
  toggleBlock = false;
  removedIngredientsList = [];
  customeToast = false;
  buttonAnimate = false;
  addedLoading = false;
  isSalatImage = false;
  cartArray = [];
  totalsCount: number;
  totalsSuma: number;

  slideOpts: SwiperOptions = {
    initialSlide: 0,
    slidesPerView: 'auto',
    speed: 500,
    spaceBetween: 15,
    autoHeight: true,
    navigation: true,
    pagination: false,
    scrollbar: false,
    lazy: {
      loadPrevNext: true,
      loadPrevNextAmount: 2,
    },
  };

  constructor(
    public navCtrl: NavController,
    private modalController: ModalController,
    private storage: Storage,
    private cartService: CartService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.product?.rec_modifiers?.forEach(element => {
        this.ingredientsList.push(element);
    });

    if(this.product?.categories?.find(el => el.slug === 'salaty')){
      this.isSalatImage = true;
    } else { this.isSalatImage = false; }
  }

  ngAfterContentChecked() {
    if (this.swiper) {
      this.swiper.updateSwiper({});
    }
  }

  sliderUpdate() {
    const interval = setInterval(() => {
      this.swiper.swiperRef.updateAutoHeight();
      setTimeout(() => {
        clearInterval(interval);
      }, 300);
    }, 1);
  }

  async openIngredients(productItem) {
    const modal = await this.modalController.create({
      component: IngredientsDetailComponent,
      swipeToClose: true,
      mode: 'ios',
      componentProps: {
        product: productItem
      }
    });
    return await modal.present();
  }

  openRemoveFromProduct(){
    this.toggleBlock = !this.toggleBlock;
  }

  addItem(item){
    item.active = !item.active;

    if(item.active){
      this.removedIngredientsList.push(item);
    } else {
      this.removedIngredientsList.splice(this.removedIngredientsList.indexOf(item), 1);
    }
    this.ingredientArray.emit(this.removedIngredientsList);
  }

  deleteIngredientFromAddedList(item){
    item.active = false;
    this.removedIngredientsList.splice(this.removedIngredientsList.indexOf(item), 1);
    this.ingredientArray.emit(this.removedIngredientsList);
  }

  clearAddedList(){
    this.removedIngredientsList = [];
    this.ingredientsList.forEach(el => {
      el.active = false;
    });
    this.ingredientArray.emit(this.removedIngredientsList);
  }

  async presentBucketToast() {
    this.customeToast = true;
  }
  customeToastCheck($event){
    this.customeToast = $event;
  }

  addToBasket(){
    this.product.quantity = 1;
    delete this.product.modifiers;
    const item = {
      id: String(this.product.id),
      quantity: '1',
      item_data: this.product
    };

    this.buttonAnimate = true;
    this.addedLoading = true;
    this.cartService.addItemToCart(item)
    .subscribe(
      (data: any) => {
        this.addedLoading = false;
        setTimeout(() => {
          this.buttonAnimate = false;
        }, 400);
        this.cartService.changeCounter(data.item_count);
        this.cartService.changeTotal(data.totals.total);
        this.presentBucketToast();
       },
       err => {
         this.alertService.presentErrorAlert(err);
       }
    );
  }
}
