import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { PushNotificationsService } from './core/services/push-notifications.service';
import {NetworkStatusService} from './core/services/network-status.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private screenOrientation: ScreenOrientation,
    private pushNotificationsService: PushNotificationsService,
    private networkStatusService: NetworkStatusService)
  {
    this.initApp();
  }

  async initApp() {
    this.platform.ready().then(() => {
      if (this.platform.is('hybrid')) {
        this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
        this.pushNotificationsService.setupPush();
        this.networkStatusService.networkStatus();
      }
    });
  }

}
