// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  origin: 'https://mama-italiano.redstone.org.ua',
  wcEndpoint: '/wp-json/wc/v3',
  wpv2Endpoint: 'wp-json/wp/v2',
  jwtEndpoint: 'wp-json/jwt-auth/v1',
  acfEndpoint: 'wp-json/acf/v3',
  rsEndpoint: 'wp-json/rs',
  coCartEndpoint: 'wp-json/cocart/v2/',
  woocommerce: {
    consumer_key: 'ck_4e94f0b82c1be9f7809a0ecd15f3e6cbbd9a6e45',
    consumer_secret: 'cs_f2e80b70bfa65db4930ade3becfeb8eb51a85f55'
  },
  oneSignalAppId: '',
  googleFirebaseSenderId: ''
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
