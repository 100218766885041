import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit {
  @Output() returnCategoryId: EventEmitter<number> = new EventEmitter();
  @Input() id: number;
  @Input() buttons: any;
  @Input() products = [];
  @Input() textAll: string;
  @Input() showAllButton: boolean;

  all = true;
  constructor( ) { }

  ngOnInit() {
    console.log(this.buttons);
  }

  filter(val: any) {
    this.returnCategoryId.emit(val);
    this.all = false;
  }

  filterAll(){
    this.returnCategoryId.emit(0);
    this.all = true;
  }
}
