import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { Subscription } from 'rxjs';
import { AlertService } from 'src/app/core/services/alert.service';
import { FavouritesProductsService } from 'src/app/core/services/favourites-products.service';
import { TrackingOrderService } from 'src/app/core/services/tracking-order.service';
import { WoocommerceCustomerService } from 'src/app/core/services/wooapi/wooApi';

@Component({
  selector: 'app-button-favorite',
  templateUrl: './button-favorite.component.html',
  styleUrls: ['./button-favorite.component.scss'],
})
export class ButtonFavoriteComponent implements OnInit {
  @Input() id: number;
  @Input() product: any;
  @Input() large = false;
  @Input() inFavorite: boolean;
  addFavoriteItem: Array<any>;
  userId: number;
  loggin = false;
  actionCur: string;

  @Input() reinit: boolean;

  checkFavorite: Subscription;

  constructor(
    public storage: Storage,
    public router: Router,
    private woocommerceCustomerService: WoocommerceCustomerService,
    private alertService: AlertService,
    private favouritesProducts: FavouritesProductsService
  ) {
    this.storage.ready().then(resp => {
      this.storage.get('token').then(token => {
          if (token) {
            this.loggin = true;
          } else {
            this.loggin = false;
          }
      });
      this.storage.get('user').then(user => {
        if (user) {
          this.userId = user.id;
        }
      });
    });
  }

  ngOnInit() {
    this.getFavoritArray().then(list => {
      this.addFavoriteItem = list;
    });
  }


  addToFavorit(): void {
    this.getFavoritArray().then(list => {
      this.addFavoriteItem = list;
      if (this.addFavoriteItem.includes(this.id) && this.addFavoriteItem !== null) {
        this.actionCur = 'delete';
        this.addFavoriteItem.splice(this.addFavoriteItem.indexOf(this.id), 1);
        this.storage.set('favouriteProductsList', this.addFavoriteItem);
      } else {
        this.actionCur = 'add';
        this.addFavoriteItem.push(this.id);
        this.storage.set('favouriteProductsList', this.addFavoriteItem);
      }
      this.favouritesProducts.loadList();
      if(this.loggin){
        this.updateFavorite(this.id, this.actionCur);
      }
    });
  }

  updateFavorite(id, action){
    const customer: any = {
      user_id: this.userId,
      product_id: id,
      action: action
    };
    // this.cabinetService.updateWishList(customer).subscribe((data: any) => {
    //   console.log(data)
    // }, (err) => {
    //   // this.alertService.presentAlert(err);
    //   // this.loadingEl.dismiss();
    // });
  }

  async getFavoritArray() {
    await this.storage.ready().then(async () => {
      await this.storage.get('favouriteProductsList').then(favorit => {
        if (favorit) {
          this.addFavoriteItem = favorit;
        } else {
          this.addFavoriteItem = [];
        }
        this.favouritesProducts.loadList();
      });
    });
    return this.addFavoriteItem;
  }

}
