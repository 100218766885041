import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NavController, Platform, PopoverController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { onlyNumbers } from 'src/app/core/validators/patterns.validator';

@Component({
  selector: 'app-information-popover',
  templateUrl: './information-popover.component.html',
  styleUrls: ['./information-popover.component.scss'],
})
export class InformationPopoverComponent implements OnInit {
  @Input() icon: string;
  @Input() title: string;
  @Input() text: string;
  @Input() form: boolean;
  @Input() button: any;
  @Input() imgSrc: string;
  @Input() closeEnable: boolean;
  backBtnSubscription: Subscription;
  code: FormControl;

  constructor(
    private popoverController: PopoverController,
    public navCtrl: NavController,
    private platform: Platform
    // private market: Market
    ) { }

  ngOnInit() {
    this.code = new FormControl(null, [Validators.required, Validators.minLength(4), Validators.pattern(onlyNumbers)]);
  }

  ionViewWillEnter() {
    this.backBtnSubscription = this.platform.backButton.subscribeWithPriority(9997, () => {
      this.closePopover();
    });
  }

  // openMarket(link){
  //   this.market.open(link);
  // }

  async closePopover(body?) {
    if (this.backBtnSubscription) {
      this.backBtnSubscription.unsubscribe();
    }
    await this.popoverController.dismiss(body);
  }

  confirmOrder() {
    this.closePopover(this.code.value);
  }

  resendOrderConfirmationCode() {
    console.log('new code was send to your device');
  }

  ionViewDidLeave() {
    if (this.backBtnSubscription) {
      this.backBtnSubscription.unsubscribe();
    };
  }
}
