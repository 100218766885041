import { Component, Input, OnInit } from '@angular/core';
import { OrderExtendedInterface } from 'src/app/core/interfaces/order-extended.interface';

@Component({
  selector: 'app-order-info',
  templateUrl: './order-info.component.html',
  styleUrls: ['./order-info.component.scss'],
})
export class OrderInfoComponent implements OnInit {
  @Input() inThankPage: boolean;
  @Input() order: OrderExtendedInterface;
  constructor() { }

  ngOnInit() {}

}
