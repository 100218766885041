import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Storage } from '@ionic/storage';
import { ProductExtendedInterface } from 'src/app/core/interfaces/product-extended.interface';
import { AlertService } from 'src/app/core/services/alert.service';
import { CartService } from 'src/app/core/services/cart.service';

@Component({
  selector: 'app-product-detail-footer',
  templateUrl: './product-detail-footer.component.html',
  styleUrls: ['./product-detail-footer.component.scss'],
})
export class ProductDetailFooterComponent implements OnInit {
  @Input() product: ProductExtendedInterface;
  @Input() totalPrice: number;
  @Input() ingredientArray = [];
  @Input() ingredientPrice: number;
  @Output() clearModifiers: EventEmitter<boolean> = new EventEmitter();
  buttonAnimate = false;
  addedLoading = false;
  isDetail = true;
  oldPrice: number;
  count = 1;
  customeToast: boolean;

  cartArray = [];
  totalsCount: number;
  totalsSuma: number;

  constructor(
    private storage: Storage,
    private cartService: CartService,
    private alertService: AlertService
  ) {
  }

  ngOnInit() {
    this.product.quantity = 1;
  }

  changeQuantity($event){
    this.count = $event;
    console.log(this.product);
  }

  clickToBuy(){
    delete this.product.modifiers;
    this.product.quantity = this.count;
    if(this.ingredientArray?.length){
      this.product.added_modifiers = this.ingredientArray;
    }

    // delete this.product.modifiers;
    const item = {
      id: String(this.product.id),
      quantity: String(this.count),
      item_data: this.product
    };
    this.buttonAnimate = true;
    this.addedLoading = true;
    this.cartService.addItemToCart(item)
    .subscribe(
      (data: any) => {
        this.addedLoading = false;
        setTimeout(() => {
          this.buttonAnimate = false;
        }, 400);
        this.cartService.changeCounter(data.item_count);
        this.cartService.changeTotal(data.totals.total);
        this.presentBucketToast();
       },
       err => {
         this.alertService.presentErrorAlert(err);
       }
    );
    this.clearModifiers.emit(true);
  }

  customeToastCheck($event){
    this.customeToast = $event;
  }
  async presentBucketToast() {
    this.customeToast = true;
  }
}
