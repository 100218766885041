import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalizationService } from '../services/localization.service';

@Injectable({ providedIn: 'root' })
export class AppResolver implements Resolve<any> {
  constructor(
    private localizationService: LocalizationService
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return this.localizationService.findAndApplyCurrentAppLanguage();
  }
}
