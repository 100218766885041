import { Injectable } from '@angular/core';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationsService {

  constructor(private oneSignal: OneSignal,
              private storage: Storage,
              private router: Router) {
  }

  setupPush() {
    // I recommend to put these into your environment.ts
    this.oneSignal.startInit(environment.oneSignalAppId, environment.googleFirebaseSenderId);

    this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.Notification);

    this.oneSignal.getIds().then(ids => {
      this.storage.set('playerID', ids.userId);
    });

    // Notifcation was received in general
    // DONT DELETE!!!!
    // this.oneSignal.handleNotificationReceived().subscribe(data => {
    //   // const msg = data.payload.body;
    //   // const title = data.payload.title;
    //   // const additionalData = data.payload.additionalData;
    //
    //   console.log('handleNotificationReceived: ', data);
    // });

    // Notification was really clicked/opened
    this.oneSignal.handleNotificationOpened().subscribe(data => {
      // const additionalData = data.notification.payload.additionalData;
      console.log('handleNotificationOpened: ', data);
      //
      let url = data.notification.payload.launchURL;
      // launchURL param starts with 'x:/' + route to necessary page
      if (url && url.substring(0, 3) === 'x:/') {
        url = url.substring(3);
        // user clicks notification while the app is opened
        if (data.notification.isAppInFocus) {
          this.router.navigateByUrl(url);
          // user clicks notification while the app is closed
        } else {
          // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
          function delay(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
          }
          // firstly navigate to home page, if some necessary data for app to work correctly is loaded there
          this.router.navigateByUrl('route-to-home-main-page').then(() => {
            delay(1000).then(() => this.router.navigateByUrl(url));
          });
        }
      }
    });

    // DONT DELETE!!!! In - App message
    // this.oneSignal.handleInAppMessageClicked().subscribe(action => {
    //     console.log('handleInAppMessageClicked: ', action);
    //     let innerUrl = action.click_name;
    //     if (innerUrl && innerUrl.substring(0, 7) === 'route:/') {
    //         innerUrl = innerUrl.substring(7);
    //         action.closes_message = true;
    //         this.router.navigateByUrl(innerUrl);
    //     }
    // });

    this.oneSignal.endInit();
  }

}
