import { AfterContentChecked, Component, Input, OnInit, ViewChildren } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ProductExtendedInterface } from 'src/app/core/interfaces/product-extended.interface';
import SwiperCore, { EffectCoverflow, Navigation, Pagination, Scrollbar, SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
SwiperCore.use([Navigation, Pagination, Scrollbar, EffectCoverflow]);

@Component({
  selector: 'app-ingredients-detail',
  templateUrl: './ingredients-detail.component.html',
  styleUrls: ['./ingredients-detail.component.scss'],
})
export class IngredientsDetailComponent implements OnInit{
  @Input() product: ProductExtendedInterface;
  @ViewChildren('swiper') swiper: SwiperComponent;
  backBtnSubscription: Subscription;
  textAll = 'Всі';
  ingredients = [];
  ingredientsList = [];
  filterButtons = [];
  id;
  addedIngredientsList = [];
  removedIngredientsList = [];
  addedToRemoveList = [];
  ingredientsPrice = 0;
  showAllButton = false;

  slideOpts: SwiperOptions = {
    initialSlide: 0,
    slidesPerView: 'auto',
    speed: 500,
    spaceBetween: 15,
    autoHeight: true,
    navigation: true,
    pagination: false,
    scrollbar: false,
    lazy: {
      loadPrevNext: true,
      loadPrevNextAmount: 2,
    },
  };

  constructor(
    private modalController: ModalController,
    private platform: Platform
  ) {
  }

  ngOnInit() {
    if(this.product?.modifiers){
      this.ingredients = this.product?.modifiers;
      this.ingredients.forEach(el =>{
        const button = {
          id: el.id,
          name: el.name,
          url: el.image?.url
        };
        this.filterButtons.push(button);
      });
      this.id = this.ingredients[0].id;
      this.ingredientsList = this.ingredients[0].ingredients[0];
    }
    this.product?.modifiers_inside?.forEach(item => {
      this.removedIngredientsList.push(item);
    });
  }

  ionViewWillEnter() {
    this.backBtnSubscription = this.platform.backButton.subscribeWithPriority(9995, () => {
      this.cancelModal();
    });
  }

  async cancelModal() {
    if (this.backBtnSubscription) {
      this.backBtnSubscription.unsubscribe();
    }
    await this.modalController.dismiss('Close modal..');
  }

  addToRemoveInsideItem(item){
    item.active = !item.active;

    if(item.active){
      this.addedToRemoveList.push(item);
    } else {
      this.addedToRemoveList.splice(this.addedToRemoveList.indexOf(item), 1);
    }
    this.product.removed_modifiers = this.addedToRemoveList;
  }
  deleteIngredientFromRemovedList(item){
    item.active = false;
    this.addedToRemoveList.splice(this.addedToRemoveList.indexOf(item), 1);
  }
  clearRemovedList(){
    this.addedToRemoveList = [];
    this.removedIngredientsList.forEach(el => {
      el.active = false;
    });
  }


  addItem(item){
    item.active = !item.active;

    if(item.active){
      this.addedIngredientsList.push(item);
    } else {
      this.addedIngredientsList.splice(this.addedIngredientsList.indexOf(item), 1);
    }
    this.calcIngredientsPrice();
  }

  deleteIngredientFromAddedList(item){
    item.active = false;
    this.addedIngredientsList.splice(this.addedIngredientsList.indexOf(item), 1);
    this.calcIngredientsPrice();
  }
  clearAddedList(){
    this.addedIngredientsList = [];
    this.ingredients.forEach(el => {
      el.ingredients[0].forEach(element => {
        element.active = false;
      });
    });
    this.calcIngredientsPrice();
  }

  calcIngredientsPrice(){
    this.ingredientsPrice = 0;
    this.addedIngredientsList.forEach(el => {
      this.ingredientsPrice = this.ingredientsPrice + +el.price;
    });
    this.product.added_modifiers = this.addedIngredientsList;
  }

  filterList($event){
    this.id = $event;
    this.ingredientsList = this.ingredients.find(el => el.id === this.id).ingredients[0];
  }

  ionViewDidLeave() {
    if (this.backBtnSubscription) {
      this.backBtnSubscription.unsubscribe();
    };
  }
}
