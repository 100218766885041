import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { BehaviorSubject, from, ReplaySubject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  counter: any = new BehaviorSubject<number>(0);
  summary: any = new BehaviorSubject<number>(0);
  cartList: any = new ReplaySubject<Array<any>>(1);
  token: string;
  key: string;

  constructor(
    private storage: Storage,
    private http: HttpClient,
    private alertService: AlertService
  ) {
    this.getCartKey();
  }

  // total quantity for all product //
  changeCounter(num) {
    this.counter.next(num);
  }

  // total summa for all product //
  changeTotal(num) {
    this.summary.next(num);
  }

  // changing bucket list //
  getCartList(value) {
    this.cartList.next(value);
  }

  async getCartKey() {
    await this.storage.ready().then(async () => await this.storage.get('cart_key').then((cart_key) => {
        if (cart_key) {
          this.key = cart_key;
          return this.key;
        } else {
          this.storage.get('user').then((user: any) => {
            this.key = user?.id;
            return this.key;
          });
        }
      }));
    return this.key;
  }

  getCart(key?) {
    this.key = key;
    console.log(this.key);
    let options;
    if (this.key) {
      options = {
        params: {
          cart_key: this.key
        }
      };
    }
    return this.http
      .get(environment.coCartEndpoint + 'cart', options)
      .pipe(
        catchError((error) => {
          this.alertService.presentErrorAlert(error);
          return throwError(error);
        })
      );
  }

  addItemToCart(item) {
    console.log(item);
    let options;
    if (this.key) {
      options = {
        params: {
          cart_key: this.key,
        }
      };
    }
    return this.http
      .post(environment.coCartEndpoint + 'cart/add-item', item, options)
      .pipe(
        map((response: any) => {
          this.storage.set('cart_key', response?.cart_key);
          this.key = response?.cart_key;
          return response;
        }),
        catchError((error) => {
          this.alertService.presentErrorAlert(error);
          return throwError(error);
        })
      );
  }

  updateItemInCart(item) {
    let options;
    if (this.key) {
      options = {
        params: {
          cart_key: this.key,
        }
      };
    }
    return this.http
      .post(environment.coCartEndpoint + 'cart/item/' + item?.item_key, item, options)
      .pipe(
        catchError((error) => {
          this.alertService.presentErrorAlert(error);
          return throwError(error);
        })
      );
  }

  deleteItemFromCart(id) {
    let options;
    if (this.key) {
      options = {
        params: {
          cart_key: this.key,
        }
      };
    }
    return this.http
      .delete(environment.coCartEndpoint + 'cart/item/' + id, options)
      .pipe(
        catchError((error) => {
          this.alertService.presentErrorAlert(error);
          return throwError(error);
        })
      );
  }

  clearCart() {
    console.log(this.key);
    let options;
    if (this.key) {
      options = {
        params: {
          cart_key: this.key,
        }
      };
    }
    return this.http
      .post(environment.coCartEndpoint + 'cart/clear', {}, options)
      .pipe(
        map((response: any) => {
          this.storage.remove('cart_key');
          return response;
        }),
        catchError((error) => {
          this.alertService.presentErrorAlert(error);
          return throwError(error);
        })
      );
  }


  getCartTotal(){
    let options;
    if (this.key) {
      options = {
        params: {
          cart_key: this.key,
        }
      };
    }
    return this.http
      .get(environment.coCartEndpoint + 'cart/totals')
      .pipe(
        catchError((error) => {
          this.alertService.presentErrorAlert(error);
          return throwError(error);
        })
      );
  }
}
