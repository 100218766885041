import { Component, OnInit } from '@angular/core';
import { ModalController, NavController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { OrderExtendedInterface } from 'src/app/core/interfaces/order-extended.interface';

@Component({
  selector: 'app-order-thanks',
  templateUrl: './order-thanks.component.html',
  styleUrls: ['./order-thanks.component.scss'],
})
export class OrderThanksComponent implements OnInit {
  order: OrderExtendedInterface = {
    id: 211,
    number: '657856342',
    date_created: '21.08.2021 12:17',
    status_type: 'successful',
    status_title: 'Виконано',
    total: '1 236',
    payment_method_title: 'Готівкою, решта з 1500',
    delivery_method: 'Адресна доставка',
    billing: {address_1: 'вул. Ак. Андрія Сахарова, 60'},
    line_items: [
      {
        name: 'Мафіо ді цізаріні',
        quantity: 1,
        price: '230',
        modificators: [{name: 'Бекон'},{name: 'Сир брі'}, {name: 'Малиновий джем'}, {name: 'Печериці'}],
        removed_modificators: [{name: 'Моцарела'}]
      },
      {
        name: 'Мафіо ді цізаріні',
        quantity: 1,
        price: '165',
        removed_modificators: [{name: 'Моцарела'}]
      },
      {
        name: 'Мафіо ді цізаріні',
        quantity: 1,
        price: '140',
        modificators: [{name: 'Бекон'},{name: 'Сир брі'}, {name: 'Малиновий джем'}]
      }
    ]
  };
  inThankPage = false;
  backBtnSubscription: Subscription;

  constructor(
    public navCtrl: NavController,
    private modalController: ModalController,
    private platform: Platform
  ) { }

  ngOnInit() { }
  ionViewWillEnter() {
    this.backBtnSubscription = this.platform.backButton.subscribeWithPriority(9995, () => {
      this.cancelModal();
    });
  }

  async cancelModal() {
    if (this.backBtnSubscription) {
      this.backBtnSubscription.unsubscribe();
    };
  }

  async redirectTo(route) {
    await this.modalController.dismiss();
    this.navCtrl.navigateForward([route]);
    this.cancelModal();
  }

  ionViewDidLeave() {
    if (this.backBtnSubscription) {
      this.backBtnSubscription.unsubscribe();
    };
  }
}
