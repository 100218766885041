import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { delay, finalize } from 'rxjs/operators';
import { CartService } from 'src/app/core/services/cart.service';
import { Order } from 'src/app/core/services/wooapi/wooApi';

@Component({
  selector: 'app-basket',
  templateUrl: './basket.component.html',
  styleUrls: ['./basket.component.scss'],
})
export class BasketComponent implements OnInit {
  @Output() returnChangeCartItems: EventEmitter<any> = new EventEmitter<any>();
  @Output() emptyCart: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() cart: any;
  // loading = true;
  order: Order;
  disabledButton: boolean;


  constructor(private cartService: CartService) {}

  ngOnInit() {
    this.order = {line_items: this.cart.items};
    console.log('cart -> ', this.order);
    this.setCartData(this.cart);
    // this.cartService.getCartKey().then((key: string) => {
    //   this.cartService.getCart(key)
    //   .pipe(finalize(() => {
    //     this.loading = false;
    //   }))
    //   .subscribe(
    //     (data: any) => {
    //       this.order = {line_items: data.items};
    //       console.log('cart -> ', this.order);
    //       this.setCartData(data);
    //     },
    //     (error) => { console.log(error); }
    //   );
    // });
  }

  changeQuantity(newQuantity, product, index) {
    this.disabledButton = true;
    if (newQuantity) {
      product.quantity.value = newQuantity;
      this.cartService
        .updateItemInCart({
          item_key: product?.item_key,
          quantity: newQuantity,
        })
        .subscribe(
          (data: any) => {
            this.setCartData(data);
            console.log('update -> ', data);
          },
          (error) => { console.log(error); }
        );
    } else {
      this.removeFromBasket(product, index);
    }
  }

  removeFromBasket(product, index) {
    this.order.line_items.splice(index, 1);
    console.log(this.order.line_items);
    if(this.order.line_items.length){
      this.emptyCart.emit(false);
    } else {
      this.emptyCart.emit(true);
    }
    this.cartService.deleteItemFromCart(product?.item_key).subscribe(
      (data: any) => {
        this.setCartData(data);
        console.log('remove -> ', data);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  removeInsideModifiers(product, arrayOfInsideModifiers, index) {
    arrayOfInsideModifiers.splice(index, 1);
    product.cart_item_data.added_modifiers = arrayOfInsideModifiers;
    console.log(this.order);
    this.cartService.addItemToCart({ id: product?.id + '', quantity: product?.quantity?.value + '', item_data: product?.cart_item_data })
      .pipe(finalize(() => {
        this.removeFromBasket(product, index);
        console.log(this.order.line_items.findIndex(index => index.id === product.id));

        this.order.line_items.splice(this.order.line_items.findIndex(index => index.id === product.id), 0, product);
      }))
      .subscribe(
        (data: any) => {
          console.log('add same product with new modifiers -> ', data);
        },
        (error) => { console.log(error); }
      );
  }

  setCartData(data){
    this.cartService.changeCounter(data.item_count);
    this.cartService.changeTotal(data.totals.total);
    this.disabledButton = false;
    this.returnChangeCartItems.emit(this.order.line_items);
  }
}
