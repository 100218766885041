import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[phoneInput]',
})
export class FocusPhoneInputDirective {
  constructor(private elementRef: ElementRef) { }

  @HostListener('ngModelChange') modelChanges() {
    if (this.elementRef.nativeElement.value === '+380 _ ___ __ __' || !this.elementRef.nativeElement.value) {
      this.elementRef.nativeElement.parentElement.classList.remove(
        'item-has-value'
      );
    } else {
      this.elementRef.nativeElement.parentElement.classList.add(
        'item-has-value'
      );
    }
  }

  @HostListener('focus') inputFocus() {
    this.elementRef.nativeElement.parentElement.classList.toggle(
      'item-has-focus'
    );
  }

  @HostListener('blur') inputBlur() {
    this.elementRef.nativeElement.parentElement.classList.toggle(
      'item-has-focus'
    );
  }
}
