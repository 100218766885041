import {Injectable} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {ProductExtendedInterface} from '../interfaces/product-extended.interface';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})

export class FavouritesProductsService {
  listLoaded = false;
  favouriteProductsList: ProductExtendedInterface[] = [];
  public favouriteProductsList$: ReplaySubject<ProductExtendedInterface[]> = new ReplaySubject<ProductExtendedInterface[]>(1);

  constructor(private storage: Storage) {
  }

  async loadList() {
    await this.storage.ready().then(async () => {
      await this.storage.get('favouriteProductsList').then(data => {
        if (data) {
          this.favouriteProductsList = data;
        } else {
          this.favouriteProductsList = [];
        }
        this.listLoaded = true;
        this.favouriteProductsList$.next(this.favouriteProductsList);
      });
    });
  }

  get getData() {
    if (!this.listLoaded) {
      this.loadList();
    }
    return this.favouriteProductsList$;
  }

  addToList(product: ProductExtendedInterface) {
    this.favouriteProductsList.push(product);
    this.favouriteProductsList$.next(this.favouriteProductsList);
  }

  removeFromList(productId: number) {
    this.favouriteProductsList.filter(product => product.id !== productId);
    this.favouriteProductsList$.next(this.favouriteProductsList);
  }
}
