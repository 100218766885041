import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone',
})
export class PhonePipe implements PipeTransform {
  transform(phoneValue: string, trimmed?: boolean): any {
    let newPhone: string = '';
    if (phoneValue) {
      phoneValue + '';
      if (trimmed) {
        newPhone = phoneValue.replace(/\+|\(|\)|\s/g, '').slice(-10);
      } else {
        newPhone = '+38 0' + phoneValue.slice(0, 2) + ' ' + phoneValue.slice(2, 5) + ' ' + phoneValue.slice(5,7) + ' ' + phoneValue.slice(7);
      }
    }
    return newPhone;
  }
}
