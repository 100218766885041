import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {AppResolver} from './core/resolvers/app.resolver';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule),
    resolve: { resolveData: AppResolver }
  },
  {
    path: 'registration',
    loadChildren: () => import('src/app/pages/auth/registration/registration.module').then( m => m.RegistrationPageModule)
  },
  {
    path: 'recover-password',
    loadChildren: () => import('src/app/pages/auth/recover-password/recover-password.module').then( m => m.RecoverPasswordPageModule)
  },
  {
    path: 'promotions/:promotionsId',
    loadChildren: () => import('./pages/promotions-detail/promotions-detail.module').then( m => m.PromotionsDetailPageModule)
  },
  {
    path: 'about/:id',
    loadChildren: () => import('src/app/pages/info/pages/about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'delivery/:id',
    loadChildren: () => import('src/app/pages/info/pages/delivery-and-payment/delivery-and-payment.module').then(
      m => m.DeliveryAndPaymentPageModule)
  },
  {
    path: 'vakancies/:id',
    loadChildren: () => import('src/app/pages/info/pages/vacancies/vacancies.module').then( m => m.VacanciesPageModule)
  },
  {
    path: 'vakancies/:id/:vacancyId',
    loadChildren: () => import('src/app/pages/info/pages/vacancies-detail/vacancies-detail.module').then( m => m.VacanciesDetailPageModule)
  },
  {
    path: 'faq/:id',
    loadChildren: () => import('src/app/pages/info/pages/faq/faq.module').then( m => m.FAQPageModule)
  },
  {
    path: 'privacy-policy/:id',
    loadChildren: () => import('src/app/pages/info/pages/policy/policy.module').then( m => m.PolicyPageModule)
  },
  {
    path: 'product-detail/:id',
    loadChildren: () => import('src/app/pages/product-detail/product-detail.module').then( m => m.ProductDetailPageModule)
  },
  {
    path: 'personal-data',
    loadChildren: () => import('src/app/pages/cabinet/pages/personal-data/personal-data.module').then( m => m.PersonalDataPageModule)
  },
  {
    path: 'delivery-address',
    loadChildren: () => import('src/app/pages/cabinet/pages/delivery-address/delivery-address.module').then(
        m => m.DeliveryAddressPageModule)
  },
  {
    path: 'add-new-address',
    loadChildren: () => import('src/app/pages/cabinet/pages/add-new-address/add-new-address.module').then( m => m.AddNewAddressPageModule)
  },
  {
    path: 'order-history',
    loadChildren: () => import('src/app/pages/cabinet/pages/order-history/order-history.module').then( m => m.OrderHistoryPageModule)
  },
  {
    path: 'order-detail/:orderId',
    loadChildren: () => import('src/app/pages/cabinet/pages/order-detail/order-detail.module').then( m => m.OrderDetailPageModule)
  },
  {
    path: 'change-password',
    loadChildren: () => import('src/app/pages/cabinet/pages/change-password/change-password.module').then( m => m.ChangePasswordPageModule)
  },
  {
    path: 'favorite',
    loadChildren: () => import('src/app/pages/favorite/favorite.module').then( m => m.FavoritePageModule)
  },
  {
    path: 'checkout',
    loadChildren: () => import('src/app/pages/checkout/checkout.module').then( m => m.CheckoutPageModule)
  },
  {
    path: 'constructor',
    loadChildren: () => import('./pages/constructor/constructor.module').then( m => m.ConstructorPageModule)
  },
  {
    path: 'order-tracking',
    loadChildren: () => import('./pages/order-tracking/order-tracking.module').then( m => m.OrderTrackingPageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
