import {Injectable,} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
// import { Router } from '@angular/router';
import {catchError, mergeMap, tap} from 'rxjs/operators';
import {from, Observable, throwError} from 'rxjs';

// import { AuthService } from './auth.service';
import {environment} from '../../../environments/environment';
import {NavController} from '@ionic/angular';
import { Storage } from '@ionic/storage';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  private requestUrl = '';
  private setAuthorization = false;

  constructor(
    private navCtrl: NavController,
    private storage: Storage
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const promise = this.storage.get('token');

    return from(promise).pipe(
      mergeMap(token => {
        let clonedReq = this.addToken(request, token);

        if (request.url.includes('jwt') ||
          request.url.includes('acf') ||
          request.url.includes('v2') ||
          request.url.includes('/rs') ||
          request.url.includes('contact-form-7') ||
          request.url.includes('/liqpay')
        ) {
          this.requestUrl = `${environment.origin}/${request.url}`;
        } else if (
          request.url.includes('assets') ||
          request.url.includes('/getAllPages')
        ) {
          this.requestUrl = `${request.url}`;
        } else {
          this.requestUrl = `${environment.origin}${environment.wcEndpoint}/${request.url}${this.includeWooAuth(request.url)}`;
        }
        if (request.url.includes('/orders') || request.url.includes('/products')) {
          this.requestUrl = `${environment.origin}${request.url}${this.includeWooAuth(request.url)}`;
        }

        clonedReq = request.clone({
          setHeaders: {
            // eslint-disable-next-line @typescript-eslint/naming-convention,max-len
            Authorization: (this.setAuthorization && clonedReq.headers.get('Authorization')) ? 'Bearer ' + clonedReq.headers.get('Authorization') : '',
          },
          url: this.requestUrl
        });

        return next.handle(clonedReq).pipe(
          tap(async event => {
            if (event instanceof HttpResponse) {
              const tokenId = event.headers.get('Authorization');
              if (tokenId) {
                await this.storage.set('token', event.headers.get('Authorization'));
              }
            }
          }),
          catchError(err => {
            if (err instanceof HttpErrorResponse) {
              if (err.status === 401) {
                // this.storage.remove('token');
                // this.navCtrl.navigateForward(['']);
              }
            }
            return throwError(err);
          })
        );
      })
    );
  }

  private includeWooAuth(url) {
    const wooAuth = `consumer_key=${environment.woocommerce.consumer_key}&consumer_secret=${environment.woocommerce.consumer_secret}`;
    const hasQuery = url.includes('?');
    let returnUrl = '';
    if (hasQuery) {
      returnUrl = wooAuth;
    } else {
      returnUrl = '?' + wooAuth;
    }
    return returnUrl;
  }

  private addToken(request: HttpRequest<any>, token: any) {
    if (token) {
      return request.clone({
        setHeaders: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: token
        }
      });
    }
    return request;
  }
}
