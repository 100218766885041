import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoadingController, PopoverController } from '@ionic/angular';
import { finalize } from 'rxjs/operators';
import {
  emailPattern,
  onlyNumbers,
} from 'src/app/core/validators/patterns.validator';
import { InformationPopoverComponent } from '../information-popover/information-popover.component';
import { ContactForm7Service } from './contactForm7.service';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
  providers: [ContactForm7Service],
})
export class ContactFormComponent implements OnInit {
  @Input() formId: number;
  @Input() formTitle = 'Форма зворотнього зв\'язку';
  @Input() contacts: any;
  @Input() recruiter: any;
  @Input() vacancy: string;
  contactForm: FormGroup;
  file: any;
  selectedFile: File;
  fileValidationError: string;

  constructor(
    private loadingController: LoadingController,
    private popoverController: PopoverController,
    private contactForm7Service: ContactForm7Service
  ) {}

  ngOnInit() {
    this.contactForm = new FormGroup({
      first_name: new FormControl(null, [Validators.required]),
      tel: new FormControl(null, [
        Validators.required,
        Validators.minLength(9),
        Validators.pattern(onlyNumbers),
      ]),
      email: new FormControl(null, [Validators.required, Validators.pattern(emailPattern)]),
      textarea: new FormControl(null, [Validators.required]),
      file: new FormControl(null),
    });
  }

  onChange(event) {
    const files = event.target.files;
    this.file = files[0];

    if (this.file) {
      if (this.file.size > 3145728) {
        this.selectedFile = undefined;
        this.fileValidationError = 'Файл більший ніж 3 Мб';
      } else if (
        ![
          'pdf',
          'msword',
          'vnd.openxmlformats-officedocument.wordprocessingml.document',
          'vnd.ms-excel',
          'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ].includes(this.file.type.split('/')[1])
      ) {
        this.selectedFile = undefined;
        this.fileValidationError = 'Невірний формат файлу';
      } else {
        this.fileValidationError = '';
        this.selectedFile = this.file;
        const reader = new FileReader();
        reader.readAsDataURL(this.file);
        reader.onload = () => {
          this.file = reader.result;
        };
      }
    } else {
      this.contactForm.get('file').reset();
      this.file = undefined;
      this.selectedFile = undefined;
      this.fileValidationError = '';
    }
  }

  deleteFile() {
    if (this.file) {
      this.contactForm.get('file').reset();
      this.file = undefined;
      this.selectedFile = undefined;
    }
  }

  async sendForm(event) {
    const formData = new FormData(event.target);
    if (this.vacancy) {formData.append('vacancies', this.vacancy);};
    // console.log(this.contactForm.value, formData);
    const loading = await this.loadingController.create({
      message: 'Надсилаємо...',
      duration: 3000,
      mode: 'ios',
    });
    await loading.present().then(() => {
      this.contactForm7Service
        .sendForm(this.formId, formData)
        .pipe(finalize(() => {loading.dismiss();}))
        .subscribe(
          (data) => {this.presentSuccessPopover();},
          (error) => { }
        );
    });
  }

  async presentSuccessPopover() {
    const popover = await this.popoverController.create({
      component: InformationPopoverComponent,
      cssClass: 'information-popover',
      showBackdrop: true,
      backdropDismiss: true,
      mode: 'ios',
      componentProps: {
        icon: '/assets/img/emoji-happy.png',
        title: 'Дякуємо за ваше повідомлення!',
        text: 'Ваше повідомлення успішно надіслано. Наші менеджери зв’яжуться з вами',
        button: {
          name: 'На головну',
          route: '/home/tabs/tab/categories',
        },
        closeEnable: true,
      },
    });
    return await popover.present();
  }
}
