import {
    ChangeDetectorRef,
    Component,
    ElementRef, EventEmitter, forwardRef, HostListener, Input,  Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IonInput } from '@ionic/angular';

@Component({
  selector: 'app-autocomplete-input',
  templateUrl: './autocomplete-input.component.html',
  styleUrls: ['./autocomplete-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteInputComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: AutocompleteInputComponent,
      multi: true
    }
  ]
})
export class AutocompleteInputComponent implements ControlValueAccessor {
  @ViewChild(IonInput, { static: false }) input: IonInput;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('items') set setItems( val: {name: string}[]) {
    this.items = val;
    this.list = this.items;
    this.filterList(this.selectValue);
  };
  @Input() disabled = false;
  @Input() valueStreet: string;
  @Input() placeholder: string;
  @Output() selected = new EventEmitter<any>();

  items: {name: string}[];
  itemSize = 44;
  selectValue: string = null;
  open = false;
  list: {name: string}[] = [];
  viewportHeight = 300;

  constructor(
    private elementRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef
  ) {
      if(this.valueStreet){
        this.selectValue = this.valueStreet;
      }
  }

  @HostListener('document:click', ['$event'])
  clickout(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.close();
    }
  }


  onChangeInput($event) {
    this.filterList($event.detail.value);
  }

  openList(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    if (!this.disabled) {this.open = true;}
    this.onTouched();
  }

  onSelect(item: any) {
    this.selectValue = item;
    this.selected.emit(item);
    this.onChange(this.selectValue);
    this.onTouched();
    this.close();
  }

  close() {
    this.open = false;
    this.changeDetectorRef.detectChanges();
    if (this.input?.value) {
      this.input.value = this.selectValue;
    }
  }

  filterList(val) {
    if (Array.isArray(this.items) && val) {
      this.list = this.items.filter(el => el.name.toLowerCase().includes(val.toLowerCase()));
    } else if (Array.isArray(this.items) && !val && !this.selectValue) {
      this.list = this.items;
    } else {
      this.list = this.items;
      this.selectValue = null;
      this.onChange(this.selectValue);
    }
    this.calcViewportHeight();
  }

  calcViewportHeight() {
    setTimeout(() => {
      if (this.list?.length) {
        const height = this.list.length * this.itemSize + 10;
        this.viewportHeight = (height > 300) ? 300 : this.list.length ? height : this.itemSize;
      }
    }, 200);
  }

  onChange = (value: any) => { };

  onTouched = () => { };

  writeValue(value: any): void {
    this.selectValue = value;
    if (value) {
      this.filterList(value);
    }
    this.onChange(value);
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  validate({ value }: FormControl) {
    return !!value ? true : false;
  }
}
