import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SwiperModule } from 'swiper/angular';
import { AutocompleteInputComponent } from './components/autocomplete-input/autocomplete-input.component';
import { BasketComponent } from './components/basket/basket.component';
import { ButtonFavoriteComponent } from './components/button-favorite/button-favorite.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { ContentComponent } from './components/content/content.component';
import { CounterComponent } from './components/counter/counter.component';
import { CustomeToastrComponent } from './components/custome-toastr/custome-toastr.component';
import { FiltersComponent } from './components/filters/filters.component';
import { ImgComponent } from './components/img/img.component';
import { ProductCardComponent } from './components/product-card/product-card.component';
import { ProductDetailFooterComponent } from './components/product-detail-footer/product-detail-footer.component';
import { InformationPopoverComponent } from './components/information-popover/information-popover.component';
import { ThankComponent } from './components/thank/thank.component';
import { TopPopoverComponent } from './components/top-popover/top-popover.component';
import { PreviewThumbLinkComponent } from './components/preview-thumb-link/preview-thumb-link.component';
import { PreviewThumbLinkSkeletonComponent } from './components/preview-thumb-link-skeleton/preview-thumb-link-skeleton.component';
import { InformerBlockComponent } from './components/informer-block/informer-block.component';
import { IngredientComponent } from './components/ingredient/ingredient.component';
import { IngredientsDetailComponent } from './components/ingredients-detail/ingredients-detail.component';
import { MapComponent } from './components/map/map.component';
import { OrderInfoComponent } from './components/order-info/order-info.component';
import { OrderThanksComponent } from './components/order-thanks/order-thanks.component';
import { PhonePipe } from './pipes/phone.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { GoogleMapsModule } from '@angular/google-maps';
import { FocusPhoneInputDirective } from './directives/focus-phone-input.directive';
import { IonicModule } from '@ionic/angular';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { ReactiveFormsModule } from '@angular/forms';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
    declarations: [
        AutocompleteInputComponent,
        BasketComponent,
        CounterComponent,
        ProductDetailFooterComponent,
        ProductCardComponent,
        ButtonFavoriteComponent,
        CustomeToastrComponent,
        ContentComponent,
        ContactFormComponent,
        ImgComponent,
        FiltersComponent,
        InformationPopoverComponent,
        ThankComponent,
        TopPopoverComponent,
        PreviewThumbLinkComponent,
        PreviewThumbLinkSkeletonComponent,
        InformerBlockComponent,
        IngredientComponent,
        IngredientsDetailComponent,
        MapComponent,
        OrderInfoComponent,
        OrderThanksComponent,
        PhonePipe,
        SafeHtmlPipe,
        FocusPhoneInputDirective
    ],
    imports: [
      CommonModule,
      IonicModule,
      SwiperModule,
      NgxMaskModule.forRoot(maskConfig),
      ScrollingModule,
      GoogleMapsModule,
      ReactiveFormsModule
    ],
    exports: [
        AutocompleteInputComponent,
        BasketComponent,
        CounterComponent,
        ProductDetailFooterComponent,
        ProductCardComponent,
        ButtonFavoriteComponent,
        CustomeToastrComponent,
        ContentComponent,
        ContactFormComponent,
        ImgComponent,
        FiltersComponent,
        InformationPopoverComponent,
        ThankComponent,
        TopPopoverComponent,
        PreviewThumbLinkComponent,
        PreviewThumbLinkSkeletonComponent,
        InformerBlockComponent,
        IngredientComponent,
        IngredientsDetailComponent,
        MapComponent,
        OrderInfoComponent,
        OrderThanksComponent,
        PhonePipe,
        SafeHtmlPipe,
        FocusPhoneInputDirective,
        SwiperModule,
        NgxMaskModule
    ]
  })
export class SharedModule {
}
