import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() height = '300px';
  @Input() center: google.maps.LatLngLiteral = {
    lat: 49.836768,
    lng: 24.034245,
  };
  @Input() zoom = 11;
  @Input() address: string;
  @Input() markerArray: Array<google.maps.MarkerOptions> = [];
  @Output() currentTerminal: EventEmitter<any> = new EventEmitter<any>();
  @Input() mapZonesArray: Array<any>;
  @Input() onCheckout: boolean;
  @ViewChild('maps', { static: false }) maps: any;
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;
  // Map settings
  mapOptions: google.maps.MapOptions = {
    center: this.center,
    zoom: this.zoom,
    maxZoom: 16,
    minZoom: 3,
    draggable: true,
    disableDefaultUI: true,
    clickableIcons: false,
    disableDoubleClickZoom: true,
    fullscreenControl: false,
    gestureHandling: 'cooperative',
    keyboardShortcuts: false,
    mapTypeControl: false,
    noClear: false,
    panControl: false,
    rotateControl: false,
    scaleControl: false,
    scrollwheel: false,
    streetViewControl: false,
    zoomControl: false,
  };

  polygonOptions: Array<google.maps.PolygonOptions> = [];
  zoneInfo: any;
  zonePriceData: any;
  infoMarker = '';

  constructor( private storage: Storage) {
    this.storage.get('zonePriceData').then(zonePriceData => {
      if (zonePriceData) {
        this.zonePriceData = zonePriceData;
        console.log(this.zonePriceData);
      }
    });
  }

  ngOnInit() { }

  ngOnChanges() {
    if(this.address){
      this.addMarkerByLocation();
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.setMap();
      this.addPolygon();
    }, 300);
  }

  openInfoWindow(marker: MapMarker) {
    if (marker.marker.getTitle()) {
      this.infoMarker = marker.marker.getTitle();
      this.infoWindow.open(marker);
    }
  }

  setMap() {
    const bounds = new google.maps.LatLngBounds();
    if (this.markerArray?.length) {
      this.markerArray.forEach((marker) => {
        // bounds.extend(new google.maps.Marker(marker).getPosition());
        bounds.extend(marker.position);
      });
      this.maps.fitBounds(bounds);
    }
  }

  addPolygon() {
    this.mapZonesArray?.forEach((zone) => {
      zone.zoneLocations?.forEach(element => {
        const polygon = {
          fillColor: zone.fillColor,
          fillOpacity: zone.fillOpacity,
          strokeColor: zone.strokeColor,
          strokeOpacity: zone.strokeOpacity,
          strokeWeight: zone.strokeWeight,
          type_zone: zone.type_zone,
          paths: element.path
        };
        this.polygonOptions.push(polygon);
      });
    });

    // console.log(this.mapZonesArray);
    // console.log(this.polygonOptions);
  }

  // add marker by location
  addMarkerByLocation() {
    // console.log(this.address);
    let position;
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode(
      { address: this.address + 'Львів' },
      async (results: any, status) => {
        position = results[0].geometry.location;
        this.markerArray = [];
        this.markerArray.push({
          position: position.toJSON(),
          draggable: false,
          icon: './assets/img/icon/user-marker.svg'
        });
        this.setMap();
        this.checkIfPolygonContainsMarker(position);
      }
    );
  }

  // check if the address falls into the some polygon
  checkIfPolygonContainsMarker(point) {
    const check = google.maps.geometry.poly;
    const selectPolyWithMarker = this.polygonOptions.find((poly) =>
      check.containsLocation(point, new google.maps.Polygon(poly))
    );
    // console.log(selectPolyWithMarker);
    // console.log(
    //   this.polygonOptions.find(
    //     (zone) => zone === selectPolyWithMarker
    //   )
    // );
    const resultForEmit: any = this.polygonOptions.find(
      zone => zone === selectPolyWithMarker
    );
    // console.log(resultForEmit);
    if(resultForEmit){
      switch (resultForEmit.type_zone) {
        case 'green':
          this.zoneInfo = {
            title: 'зеленій',
            time: '29',
            minPriceOrder: this.zonePriceData?.green?.min_sum_order,
            borderColor: resultForEmit.fillColor
          };
          break;
        case 'yellow':
          this.zoneInfo = {
            title: 'жовтій',
            time: '59',
            minPriceOrder: this.zonePriceData?.yellow?.min_sum_order,
            borderColor: resultForEmit.fillColor
          };
          break;
        case 'red':
          this.zoneInfo = {
            title: 'червоній',
            time: '69',
            minPriceOrder: this.zonePriceData?.red?.min_sum_order,
            borderColor: resultForEmit.fillColor
          };
          break;
      };
    } else {
      this.zoneInfo ={ title: 'outside' };
    }
    this.currentTerminal.emit(resultForEmit);
  }
}
