import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-img',
  templateUrl: './img.component.html',
  styleUrls: ['./img.component.scss'],
})
export class ImgComponent implements OnInit {
  @Input() src = '/assets/img/placeholder.png';
  @Input() alt: string;
  @Input() cssClasses: string;
  imgLoaded = false;

  constructor() { }

  ngOnInit() {}

  imgDidLoad() {
    this.imgLoaded = true;
  }
}
